/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import { useMutation } from 'react-query'
import _ from 'lodash'
import {
	fetchActivityCategory,
	fetchAssetReportees,
	fetchDashboardSIPPayoutReportees,
	fetchChannelDetails,
	fetchFilterOptions,
	fetchTBCreatorFilterOptions,
	fetchWorkEfficiencyOptions,
	fetchFilters,
	fetchManagerDetails,
	fetchSaSummaryDrillDownData,
	fetchSASummaryReportees,
	fetchSaSummaryResource,
	fetchSIPPayoutReportees,
	fetchSIPPenUtil,
	fetchSIPLeaderBoards,
	fetchSIPPayoutWidgetData,
	fetchSipWidgetAccountDetails,
	fetchSipWidgetAccountCodeList,
	fetchSipWidgetAccountMonthWiseData,
	fetchSIPPayoutTargets,
	fetchSIPWidgets,
	fetchSIPDynamicWidgets,
	fetchSIPTopDown,
	fetchWidgetData,
	fetchWidgetDrillDownData,
	fetchWidgetDrillDownHeader,
	fetchWidgets,
	fetchVeriticalFilterOptions,
	fetchKPIProducts,
	fetchKPICategories,
	fetchKPITypes,
	updateTarget,
	updateOutlier,
	insertTarget,
	checkIsFreeze,
	fetchAccounts,
	fetchKpiCodesByReportees,
	fetchAccCodesByReportees,
	checkAccountChannelLevelTarget,
	targetSetting,
	fetchPeriodicSummary,
	fetchDottedLineManagerAccess,
	fetchTargetUpdateTimeline,
	fetchReportees,
	fetchClassifiedRoles,
	fetchSMEDrillDownData,
	accountsView,
	additionalFieldUpdate,
	fetchTataPlaySummary,
	fetchTataPlayNetChurnList,
	fetchTataPlayNetAddList,
	fetchSipPayoutManagerPredictionDetails,
	fetchSipPayoutReporteesPredictionDetails,
	fetchSipDynamicManagerPredictionDetails,
	fetchSipDynamicReporteesPredictionDetails,
	fetchTataPlayAddWidget,
	fetchTataPlayChurnWidget,
	fetchTataPlayOpenBaseList,
	fetchTataPlayCloseBaseList,
	fetchSipPayoutManagerDetails,
	fetchFilterOptionsforPeriodicSummary,
	fetchSipPayoutTBCreatorFilterOptions,
	fetchSipWidgetSARoleList,
	fetchSipWidgetDynamicMonthWiseData,
	fetchSipDynamicWidgetManagerDetails,
	fetchSipDynamicWidgetManagerDetailsQuaterly,
	fetchSipDynamicWidgetReporteeDetailsQuaterly,
} from '../services/dashboard.service'
import { getLocalAuthDetails } from '../utils/common.util'
import {
	createWidgetParams,
	processFilterOptions,
	processHorizontalBarChartData,
	processPieChartData,
	processSingleValueChart,
	processStackedChartData,
	processStatusTableData,
	processVerticalBarChartData,
} from '../utils/dashboard.utils'
import { FILTER_FLAG, FILTER_ID } from '../utils/filter.constant'
import { WIDGET_CHART_ID } from '../utils/widget-constants'
import moment from 'moment'
import { getCurrentDateUTCSTART } from '../utils/date.utils'
import { list } from '@chakra-ui/react'
import {
	fetchReportessSMEScore,
	fetchTranslations,
} from './../services/dashboard.service'
import { loadMonthlyWorkflowList } from '../services/asset.service'

export const useLoadApplication = () => {
	return useMutation(async () => {
		try {
			const payload = {
				organization_id: getLocalAuthDetails().organization_id,
				asset_token_auth: getLocalAuthDetails().asset_token_auth,
				asset_id: getLocalAuthDetails().asset_id,
				target_asset_id: getLocalAuthDetails().asset_id,
				flag: 27,
			}
			const res = await fetchFilterOptions(payload)
			return processFilterOptions(res)
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadFilters = () => {
	return useMutation(async (tag_type = {}, filter_organization_id = -1) => {
		try {
			if (!!tag_type) {
				let userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					filter_organization_id,
					tag_type_id: !!tag_type && tag_type.value,
				}
				const res = await fetchFilters(requestData)
				let resp = res.data.response
				resp.map(item => {
					if (item.filter_id === FILTER_ID.FINANCIAL_YEAR) {
						let years = []
						for (
							var y = moment()
								.subtract(tag_type?.value === 1778 ? 1 : 3, 'year')
								.format('YYYY');
							y < moment().add(10, 'years').format('YYYY');
							y++
						) {
							years.push({
								label: `${y} - ${Number(y) + 1}`,
								value: `${y} - ${Number(y) + 1}`,
							})
						}
						return (item.filter_inline_data = JSON.stringify(years))
					} else {
						return null
					}
				})
				return resp
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSubApplication = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadCluster = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadAssetTag1 = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}
export const useLoadAssetTag2 = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}
export const useLoadAssetTag3 = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}
export const useLoadAssetTagType1 = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}
export const useLoadAssetTagType2 = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}
export const useLoadAssetTagType3 = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}
export const useLoadWorkEfficiencyWorkflow = () => {
	return useMutation(async (payload = {}) =>
		loadWorkEfficiencyFilterOptions(payload)
	)
}
export const useLoadWorkEfficiencyIndustry = () => {
	return useMutation(async (payload = {}) =>
		loadWorkEfficiencyFilterOptions(payload)
	)
}
export const useLoadWorkEfficiencyCustomer = () => {
	return useMutation(async (payload = {}) =>
		loadWorkEfficiencyFilterOptions(payload)
	)
}

export const useLoadVeriticals = () => {
	return useMutation(async (payload = {}) =>
		loadVeriticalFilterOptions(payload)
	)
}

export const useLoadBuilding = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadFloor = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadCreator = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadTBCreator = () => {
	return useMutation(async (payload = {}) => loadTBCreatorOptions(payload))
}

export const useLoadSegment = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadAccount = () => {
	return useMutation(async (payload = {}) => loadActivityCategory(payload))
}

export const useLoadCategory = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadFamily = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadProduct = () => {
	return useMutation(async (payload = {}) => loadActivityCategory(payload))
}

export const useLoadWorkforce = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadCampaign = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const useLoadOppurtunity = () => {
	return useMutation(async (payload = {}) => loadFilterOptions(payload))
}

export const loadVeriticalFilterOptions = async payload => {
	try {
		const {
			flag,
			tag_id = 0,
			workforce_tag_id,
			selectedSubApplication,
			period_type_id,
			timeline_id,
		} = payload

		let requestData = {
			...getLocalAuthDetails(),
			workforce_tag_id,
			start_from: 0,
			limit_value: 50,
			flag,
			target_account_id: 0,
			type_flag: flag,
			page_limit: 50,
			page_start: 0,
			tag_id,
			is_search: 0,
			tag_type_id: 173,
			search_string: '',
			filter_is_search: -1,
			filter_search_string: '',
			//below parameter for populating cluster dropndown in leaderBoard
			selectedSubApplication: selectedSubApplication || 0,
			timeline_id: timeline_id,
			period_type_id: period_type_id,
		}
		const res = await fetchVeriticalFilterOptions(requestData)
		//condition for populating cluster dropndown in leaderBoard
		return requestData.selectedSubApplication === 221 &&
			(requestData.workforce_tag_id === 185 ||
				requestData.workforce_tag_id === 184 ||
				requestData.workforce_tag_id === 0)
			? processFilterOptions(res, 25, true)
			: processFilterOptions(res, flag, true)
	} catch (error) {
		console.error('Error', error)
		return error
	}
}

export const loadFilterOptions = async payload => {
	try {
		const {
			flag,
			tag_id = 0,
			tag_type_id = 0,
			cluster_id = 0,
			building_id = 0,
			workforce_id = 0,
			category_id = 0,
			target_tag_type_id = 0,
			target_activity_type_id = 0,
			tag_type_category_id = 0,
			search = '',
			base_tag_type_id = 0,
		} = payload
		const { asset_id } = getLocalAuthDetails()
		const {
			SUB_APPLICATION,
			CLUSTER,
			GEOGRAPHY,
			CREATOR,
			VERTICAL,
			OPPURTUNITY,
			CATEGORY,
			FAMILY,
			WORKFORCE_TAG,
			WORKFORCE,
			CAMPAIGN,
			ASSET_TAG1,
			ASSET_TAG2,
			ASSET_TAG3,
			ASSET_TAG_TYPE1,
			ASSET_TAG_TYPE2,
			ASSET_TAG_TYPE3,
		} = FILTER_FLAG
		let requestData = {
			...getLocalAuthDetails(),
			flag: flag,
			page_limit: 50,
			page_start: 0,
			target_asset_id: asset_id,
			application_id: tag_id,
		}
		if (flag !== SUB_APPLICATION) {
			requestData = {
				...requestData,
				workforce_type_id: 0,
				workforce_id: workforce_id,
				tag_id: 0,
				tag_type_id: tag_type_id,
				/*flag === CLUSTER || flag === GEOGRAPHY
            ? 144
            : flag === CATEGORY || flag === FAMILY
            ? 141
            : flag === OPPURTUNITY
            ? tag_type_id
            : flag === WORKFORCE
            ? [223, 224].includes(tag_type_id)
              ? tag_type_id
              : 147 //223, 224 are for topdown and bottom up process groups
            : flag === ASSET_TAG1 || flag === ASSET_TAG2 || flag === ASSET_TAG3
            ? tag_type_id
            : 110,*/
				tag_type_category_id: tag_type_category_id,
				/*flag === CATEGORY || flag === FAMILY
            ? 5
            : flag === CLUSTER || flag === GEOGRAPHY
            ? 6
            : flag === WORKFORCE
            ? 2
            : 1,*/
				base_tag_type_id:
					base_tag_type_id !== 0 ? base_tag_type_id.value : tag_type_id,
			}
			if (flag === GEOGRAPHY) {
				requestData = {
					...requestData,
					cluster_tag_id: cluster_id,
				}
			}
			if (flag === WORKFORCE) {
				requestData = {
					...requestData,
					account_id: building_id,
				}
			}
			if (flag === CREATOR) {
				requestData = {
					...requestData,
					account_id: building_id,
					cluster_tag_id: cluster_id,
				}
			}
			if (flag === FAMILY) {
				requestData = {
					...requestData,
					tag_id: category_id,
				}
			}
			if (
				flag === ASSET_TAG1 ||
				flag === ASSET_TAG2 ||
				flag === ASSET_TAG3 ||
				flag === ASSET_TAG_TYPE1 ||
				flag === ASSET_TAG_TYPE2 ||
				flag === ASSET_TAG_TYPE3
			) {
				requestData = {
					...requestData,
					resource_tag_dynamic_enabled: 2,
				}
			}
			if (flag === CAMPAIGN) {
				requestData = {
					...requestData,
					tag_type_id: target_tag_type_id,
					activity_type_id: target_activity_type_id,
					is_search: search.length ? 1 : 0,
					search_string: search,
					page_start: 0,
					page_limit: 50,
				}
			}
		}
		const res = await fetchFilterOptions(requestData)
		let processedFilter = processFilterOptions(res, requestData.flag)
		return processedFilter
	} catch (error) {
		console.log('Error', error)
		return error
	}
}

export const loadTBCreatorOptions = async payload => {
	try {
		let { organization_id, asset_id } = getLocalAuthDetails()
		let requestData = {
			organization_id,
			asset_id,
			...payload,
		}
		const res = requestData.isSipPayoutWidget
			? await fetchSipPayoutTBCreatorFilterOptions(requestData)
			: await fetchTBCreatorFilterOptions(requestData)
		let processedFilter = requestData.isSipPayoutWidget
			? processFilterOptions(res, FILTER_FLAG.TBCREATOR, true)
			: processFilterOptions(res, FILTER_FLAG.TBCREATOR)
		return processedFilter
	} catch (error) {
		console.log('Error', error)
		return error
	}
}

export const loadActivityCategory = async payload => {
	try {
		const { PRODUCT, ACCOUNT } = FILTER_FLAG
		const {
			flag,
			searchString = '',
			building_id,
			creator_id,
			segment_id,
			category_id,
			family_id,
		} = payload

		let requestData = {
			...getLocalAuthDetails(),
			target_account_id: flag === ACCOUNT ? building_id : 0,
			target_asset_id: flag === ACCOUNT ? (!!creator_id ? creator_id : 0) : 0,
			segment_id: flag === ACCOUNT ? (segment_id ? segment_id : 0) : 0,
			activity_type_category_id: flag === ACCOUNT ? 53 : 55,
			is_search: flag === ACCOUNT ? 1 : 0,
			search_string: searchString,
			page_start: 0,
			page_limit: 50,
			tag_type_category_id: flag === ACCOUNT ? 1 : 5,
			tag_type_id: 141,
			tag_id:
				flag === PRODUCT
					? category_id
						? category_id
						: 0
					: segment_id
					? segment_id
					: 0,
			activity_type_id: flag === PRODUCT ? family_id : 0,
		}
		const res = await fetchActivityCategory(requestData)
		return processFilterOptions(res, payload.flag)
	} catch (error) {
		console.log('Error', error)
		return error
	}
}

export const useSIPTopDownBottomUp = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					manager,
					segment,
					period_type_id,
					startDate,
					endDate,
					product_id,
					page_limit,
					page_start,
					flag_type,
					widget_type_id,
					widget_type_category_id,
					asset_type_id,
					asset_tag_id_1,
					asset_tag_type_id_1,
					level_id,
					timeline_id,
					financial_year,
					asset_id,
					cluster_tag_id,
					flag = 6,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					manager_asset_id: manager,
					workforce_tag_id: segment,
					flag_type,
					flag,
					period_type_id,
					asset_type_id,
					level_id,
					timeline_id,
					asset_id,
					financial_year,
					cluster_tag_id,
					asset_tag_id_1,
					asset_tag_type_id_1,
					period_start_datetime: startDate,
					period_end_datetime: endDate,
					widget_type_id,
					widget_type_category_id,
					product_id,
					start_from: page_start,
					limit_value: page_limit,
				}
				let res = await fetchSIPTopDown(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useMutateTarget = () => {
	return useMutation(async (params = {}) => {
		try {
			const { isUpdate } = params
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await (isUpdate
					? updateTarget(requestData)
					: insertTarget(requestData))
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useMutateKpiCodesByReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let { organization_id } = getLocalAuthDetails()
				const requestData = {
					organization_id,
					...params,
				}
				let res = await fetchKpiCodesByReportees(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useMutateAccCodesByReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let { organization_id } = getLocalAuthDetails()
				const requestData = {
					organization_id,
					...params,
				}
				let res = await fetchAccCodesByReportees(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useMutateCheckAccountChannelLevelTarget = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await checkAccountChannelLevelTarget(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useMutateOutlier = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await updateOutlier(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useCheckFreeze = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await checkIsFreeze(requestData)
				return res.data.response?.[0]
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useGetAccounts = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await fetchAccounts(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useTargetSetting = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await targetSetting(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useGetAccountsView = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await accountsView(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSipPayoutReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { creator, flag, period, year } = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
					workforce_tag_id: 0,
					payout_type_id: 0,
					period_type_id: !!period ? period.value : 0,
					financial_year: year.value.replaceAll(' ', ''),
				}
				let res = await fetchDashboardSIPPayoutReportees(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSipPayoutManagerDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { manager_id, timeline_id, period, year, segment } = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag: 0,
					manager_asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					period_type_id: period,
					financial_year: year,
				}
				let res = await fetchSipPayoutManagerDetails(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadAssetReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { creator, flag, timeline_id, year, periodTypeId, segment } =
					params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					timeline_id: timeline_id,
					financial_year: year,
					period_type_id: periodTypeId,
					workforce_tag_id: segment,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
				}
				let res = await fetchAssetReportees(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadPayoutAssetReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { manager, flag, segment, startDate, endDate } = params
				const requestData = {
					...getLocalAuthDetails(),
					activity_type_category_id: 65,
					manager_asset_id: manager,
					flag: flag,
					datetime_start: startDate,
					datetime_end: endDate,
					workforce_tag_id: segment,
				}
				let res = await fetchSIPPayoutReportees(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadLeaderBoards = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { segment, flag, year, timeline, vertical, cluster, role } =
					params
				const requestData = {
					...getLocalAuthDetails(),
					asset_id: 0,
					workforce_tag_id: segment,
					financial_year: year.replaceAll(' ', ''),
					flag: flag,
					period_type_id: timeline,
					vertical_tag_id: vertical,
					cluster_id: cluster,
					asset_type_id: role,
					sub_flag: 0,
				}
				let res = await fetchSIPLeaderBoards(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadAssetChannels = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { segment, manager, startDate, endDate } = params
				const requestData = {
					...getLocalAuthDetails(),
					channel_flag: segment.value === 184 ? 1 : 0,
					target_asset_id: manager && manager.asset_id,
					start_datetime: startDate + ' 00:00:00',
					end_datetime: endDate + ' 00:00:00',
					page_start: 0,
					page_limit: 50,
				}
				let res = await fetchChannelDetails(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadPeriodicSummary = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					segment,
					year,
					flag,
					subFlag,
					vertical,
					cluster,
					role,
					period_type_id,
					timeline,
					reportee,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					asset_id: reportee,
					flag: flag,
					sub_flag: subFlag,
					workforce_tag_id: segment,
					financial_year: year.replaceAll(' ', ''),
					vertical_tag_id: vertical,
					cluster_id: cluster,
					asset_type_id: role,
					period_type_id: period_type_id,
					timeline_id: timeline,
				}
				let res = await fetchPeriodicSummary(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadManagerDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { manager_id, year, periodTypeId, segment } = params
				const requestData = {
					...getLocalAuthDetails(),
					auth_asset_id: getLocalAuthDetails().asset_id,
					asset_id: manager_id,
				}
				let res = await fetchManagerDetails(requestData)
				return res.data ? res.data.response[0] : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const managerDetailsQuery = async params => {
	try {
		if (!!params) {
			const { manager_id } = params
			const requestData = {
				...getLocalAuthDetails(),
				auth_asset_id: getLocalAuthDetails().asset_id,
				asset_id: manager_id,
			}
			let res = await fetchManagerDetails(requestData)
			return res.data ? res.data.response[0] : []
		}
	} catch (error) {
		console.log('Error', error)
		return error
	}
}

export const useLoadWidgets = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let userData = getLocalAuthDetails()
				const { tag_type, creator, filter_organization_id = -1 } = params
				const requestData = {
					...userData,
					filter_organization_id,
					tag_type_id: !!tag_type && tag_type.value,
					target_asset_id: !!creator ? creator.value : 0,
					workforce_id: 0,
					page_start: 0,
					page_limit: 50,
				}
				let res = await fetchWidgets(requestData)
				return res.data ? res.data.response : []
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPWidgets = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { creator, segment, startDate, endDate, timeline, period, year } =
					params
				const requestData = {
					organization_id: getLocalAuthDetails().organization_id,
					filter_organization_id: getLocalAuthDetails().organization_id || -1,
					activity_type_category_id: 65,
					manager_asset_id: !!creator && creator.asset_id,
					page_start: 0,
					page_limit: 50,
					asset_id: getLocalAuthDetails().asset_id,
					workforce_tag_id: !!segment && segment.value,
					timeline_flag: !!timeline && timeline.value,
					sub_timeline_flag: !!period && period.value,
					datetime_end: getCurrentDateUTCSTART(endDate),
					datetime_start: getCurrentDateUTCSTART(startDate),
					year: year,
				}
				let res = await fetchSIPWidgets(requestData)
				if (res.status === 200) {
					const resp = res.data.response[1]
					const uniqueManagerKPis = resp.manager_kpi.filter(
						(item, index) =>
							resp.manager_kpi.findIndex(
								obj => obj.activity_type_id === item.activity_type_id
							) === index
					)
					const uniqueReporteeKpis = resp.reportee_kpi.filter(
						(item, index) =>
							resp.reportee_kpi.findIndex(
								obj => obj.activity_type_id === item.activity_type_id
							) === index
					)
					return {
						manager_kpi: uniqueManagerKPis,
						reportee_kpi: uniqueReporteeKpis,
						reportee_data: resp.reportee_data,
						manager_data: resp.manager_data,
					}
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPDynamicWidgets = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timelineId,
					period,
					startDate,
					endDate,
					year,
					isManagerTarget,
					flag,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: 0,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: isManagerTarget ? !!creator && creator.asset_id : 0, //userData.asset_id,
					workforce_tag_id: !!segment && segment.value,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: timelineId,
					period_type_id: period,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: 0,
					limit_value: 100,
				}
				let res = await fetchSIPDynamicWidgets(requestData)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPPayoutWidgets = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timeline,
					period,
					startDate,
					endDate,
					year,
					flag,
					reportees_asset_id_list,
					is_sip_dynamic_payout,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: 0,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: flag === 0 ? 0 : !!creator && creator.asset_id, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: '', //endDate,
					datetime_start: '', //startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: !!timeline ? timeline.value : 0,
					period_type_id: !!period ? period.value : 1,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: 0,
					limit_value: 100,
					is_sip_dynamic_payout: is_sip_dynamic_payout,
					reportees_asset_id_list: reportees_asset_id_list,
				}
				let res = await fetchSIPPayoutWidgetData(requestData)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

// export const useLoadSIPPayoutTargets = () => {
//   return useMutation(async (params = {}) => {
//     try {
//       if (!!params) {

//         const { creator, segment, timeline, period, startDate, endDate, year, isManagerTarget, flag } = params
//         const userData = getLocalAuthDetails()
//         const requestData = {
//           ...userData,
//           account_id: 0,
//           asset_type_id: 0,
//           filter_organization_id: userData.organization_id || -1,
//           manager_asset_id: !!creator && creator.asset_id,
//           asset_id: isManagerTarget ? !!creator && creator.asset_id : 0,//userData.asset_id,
//           workforce_tag_id: !!segment && segment.value,
//           datetime_end: endDate,
//           datetime_start: startDate,
//           flag: flag,
//           flag_type: 0,
//           timeline_id: !!timeline ? timeline.value : 0,
//           period_type_id: !!period? period.value: 1,
//           period_start_datetime: "",
//           period_end_datetime: "",
//           financial_year: year.value.replaceAll(" ",""),
//           start_from: 0,
//           limit_value: 100
//         }
//         let res = await fetchSIPPayoutTargets(requestData)
//         if (res.status === 200) {
//           return res.data.response
//         } else {
//           return []
//         }
//       }
//     } catch (error) {
//       console.log('Error', error)
//       return error
//     }
//   })
// }

export const useLoadWidgetDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { widgetData, widgetInlineData, queryFilters, flag } = params
				let payload = createWidgetParams(
					widgetData,
					widgetInlineData,
					queryFilters
				)
				payload = {
					...payload,
					activity_title: widgetData.activity_title,
				}
				let res = await fetchWidgetData(payload)
				const response = res.data.response
				if (!!response && response.length) {
					switch (widgetInlineData.widget_chart_id) {
						case WIDGET_CHART_ID.VERTICAL_BAR_CHART:
							return processVerticalBarChartData(
								widgetData.widget_type_id,
								widgetInlineData.filter_timeline_id,
								payload,
								response,
								queryFilters
							)
						case WIDGET_CHART_ID.STACKED_BAR_CHART:
							return processStackedChartData(
								widgetData.widget_type_id,
								widgetInlineData.filter_timeline_id,
								payload,
								response,
								queryFilters
							)
						case WIDGET_CHART_ID.PIE_CHART:
							const pieChartData = processPieChartData(
								widgetData.widget_type_id,
								payload,
								response,
								queryFilters
							)
							return pieChartData
						case WIDGET_CHART_ID.TABLE_CHART:
							let tableResponse = {
								jsonParams: payload,
								parsedWidgetData: response,
							}
							if ([542, 543].includes(widgetData.widget_type_id)) {
								tableResponse = processStatusTableData(payload, response)
							}
							return tableResponse
						case WIDGET_CHART_ID.SA2_TABLE_CHART:
							return response[1].result || []
						case WIDGET_CHART_ID.NUMERIC_CHART:
							return processSingleValueChart(
								widgetData.widget_type_id,
								widgetInlineData.widget_target_value,
								payload,
								response,
								queryFilters
							)
						case WIDGET_CHART_ID.HORIZONTAL_BAR_CHART:
							return processHorizontalBarChartData(
								widgetData.widget_type_id,
								payload,
								response,
								queryFilters
							)
						default:
							return response
					}
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSaResource = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					widgetData,
					widgetInlineData,
					queryFilters,
					verticalTagId,
					reporteeId,
					isReportee = false,
				} = params
				let payload = createWidgetParams(
					widgetData,
					widgetInlineData,
					queryFilters
				)
				payload = {
					...payload,
					filter_tag_id: verticalTagId,
				}
				if (isReportee) {
					payload = {
						...payload,
						filter_asset_id: reporteeId,
					}
				}
				let res
				if (!isReportee) {
					res = await fetchSaSummaryResource(payload)
				} else {
					res = await fetchSASummaryReportees(payload)
				}
				const response = res.data.response
				return response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadWidgetDataHeaders = () => {
	return useMutation(async (tag_type_id = 0, filter_organization_id = -1) => {
		try {
			if (!!tag_type_id) {
				let userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					filter_organization_id,
					tag_type_id,
				}
				let res = await fetchWidgetDrillDownHeader(requestData)
				let headers = []
				res.data.response.forEach(h => {
					let st_header =
						h.flag_is_reference === 1
							? {
									...h,
									flag_is_reference: 0,
									db_field_name: `${h.db_field_name}_status`,
									header_name: `${h.header_name} Status`,
							  }
							: null
					headers.push(h)
					if (!!st_header) {
						headers.push(st_header)
					}
				})
				return headers
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useSMEDrillDownData = () => {
	return useMutation(async params => {
		let userData = getLocalAuthDetails()
		let requestdata = {
			...params,
			account_id: userData.account_id,
			workforce_id: userData.workforce_id,
		}
		let selfData = []
		let reporteesData = []
		let records = []
		let res = await fetchSMEDrillDownData(requestdata)
		const response = res.data.response
		if (!!response.self) {
			selfData = selfData.concat(response.self)
		}
		if (!!response.reportees) {
			reporteesData = reporteesData.concat(response.reportees)
		}
		records = records.concat(selfData)
		records = records.concat(reporteesData)
		return records
	})
}

export const useLoadWidgetDrillDownData = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let {
					widgetData,
					searchStr = '',
					page = 0,
					filter_organization_id = -1,
				} = params
				let userData = getLocalAuthDetails()
				let requestData = {
					...widgetData,
					...userData,
					filter_organization_id,
					page_start: page,
					page_limit: 100,
					filter_is_count: 0,
					filter_is_search: !!searchStr && searchStr.length > 1 ? 1 : 0,
					filter_search_string: searchStr,
					isListComplete: false,
				}
				let records = []
				let res
				if (widgetData.isSaSummary) {
					res = await fetchSaSummaryDrillDownData(requestData)
				} else {
					res = await fetchWidgetDrillDownData(requestData)
				}
				const response = res.data.response
				response.map(listItem => {
					if (listItem.hasOwnProperty('result')) {
						if (listItem.result.length) {
							records = records.concat(listItem.result)
						}
					}
				})
				return records
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useCheckDottedLineManagerAccess = () => {
	return useMutation(async (params = {}) => {
		try {
			let res = await fetchDottedLineManagerAccess(params)
			return res.data.response
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadWidgetExportData = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let {
					widgetData,
					searchStr = '',
					isExport = false,
					page = 0,
					filter_organization_id = -1,
				} = params
				let userData = getLocalAuthDetails()
				let requestData = {
					...widgetData,
					...userData,
					filter_organization_id,
					page_start: page,
					page_limit: 100,
					filter_is_count: !!isExport ? 1 : 0,
					filter_is_search: !!searchStr && searchStr.length > 1 ? 1 : 0,
					filter_search_string: searchStr,
					isListComplete: false,
				}

				let exports = []
				let count = 0
				let exceeds = false
				let reqCount = 0
				let res
				if (widgetData.isSaSummary) {
					res = await fetchSaSummaryDrillDownData(requestData)
				} else {
					res = await fetchWidgetDrillDownData(requestData)
				}
				count = res.data.response[1].result[0].activity_count
				if (count > 5000) {
					exceeds = true
				} else {
					if (count < 100) {
						reqCount = 1
					} else {
						reqCount = parseInt(count / requestData.page_limit) + 1
					}
					requestData = {
						...requestData,
						filter_is_count: 0,
					}
					for (let i = 1; i <= parseInt(reqCount); i++) {
						let res
						if (widgetData.isSaSummary) {
							res = await fetchSaSummaryDrillDownData(requestData)
						} else {
							res = await fetchWidgetDrillDownData(requestData)
						}
						let response = res.data.response
						response.map(listItem => {
							if (listItem.hasOwnProperty('result')) {
								if (listItem.result.length) {
									exports = exports.concat(listItem.result)
								}
							}
						})
						requestData = {
							...requestData,
							page_start: requestData.page_start + requestData.page_limit,
						}
					}
				}
				return { exceeds, exports }
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export function useLoadKPIProducts() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				start_from: 0,
				limit_value: 20,
			}
			let res = await fetchKPIProducts(payload)
			return processFilterOptions(res, FILTER_FLAG.KPI_PRODUCT)
		} catch (error) {
			return error
		}
	})
}

export function useLoadKPICategories() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				filter_organization_id: userData.organization_id || -1,
				start_from: 0,
				limit_value: 20,
			}
			let res = await fetchKPICategories(payload)
			return processFilterOptions(res, FILTER_FLAG.KPI_CATEGORY)
		} catch (error) {
			return error
		}
	})
}

export function useLoadKPITypes() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				// filter_organization_id: userData.organization_id || -1,
				start_from: 0,
				limit_value: 50,
			}
			let res = await fetchKPITypes(payload)
			let data = processFilterOptions(res, FILTER_FLAG.KPI_TYPE)
			return data
		} catch (error) {
			return error
		}
	})
}

export function useGetReportees() {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let userData = getLocalAuthDetails()
				let payload = {
					...userData,
					log_asset_id: userData.asset_id,
					...params,
				}
				let res = await fetchReportees(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export function useTargetUpdateTimeline() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...params,
			}
			let res = await fetchTargetUpdateTimeline(payload)
			return res.data
		} catch (error) {
			return error
		}
	})
}

export const useLoadReportessSMEScore = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let { asset_id } = params
				let userData = getLocalAuthDetails()
				let requestData = {
					...userData,
					flag: 1,
					summary_id: 7,
					asset_id,
				}

				let res = await fetchReportessSMEScore(requestData)

				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useAssetGamificationActivityList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let { asset_id } = params
				let payload = {
					flag: 4,
					...getLocalAuthDetails(),
					asset_id,
				}
				let res = await loadMonthlyWorkflowList(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useClassifyRoleList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await fetchClassifiedRoles(payload)
				let data = processFilterOptions(res, FILTER_FLAG.CLASSIFY_ROLES)
				return data
			}
		} catch (error) {
			return error
		}
	})
}

export const loadWorkEfficiencyFilterOptions = async payload => {
	try {
		const { flag, search = '' } = payload
		const { asset_id } = getLocalAuthDetails()
		const { WORKFLOW, INDUSTRY, CUSTOMER } = FILTER_FLAG
		let requestData = {
			...getLocalAuthDetails(),
			flag: flag,
			target_asset_id: asset_id,
		}

		if (flag === WORKFLOW || flag === CUSTOMER || flag === INDUSTRY) {
			requestData = {
				...requestData,
				filter_id: flag,
				is_search: search.length ? 1 : 0,
				search_string: search,
			}
		}
		const res = await fetchWorkEfficiencyOptions(requestData)
		let processedFilter = processFilterOptions(res, flag)
		return processedFilter
	} catch (error) {
		console.log('Error', error)
		return error
	}
}

export const useAdditionalFieldUpdate = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await additionalFieldUpdate(payload)
				return res
			}
		} catch (error) {
			return error
		}
	})
}

export const useGetTranslations = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const requestData = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await fetchTranslations(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadTataPlaySummary = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlaySummary(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadTataPlayNetChurnList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlayNetChurnList(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadTataPlayNetAddList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlayNetAddList(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadTataPlayChurnWidget = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlayChurnWidget(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadTataPlayAddWidget = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlayAddWidget(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadTataPlayOpenBaseList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlayOpenBaseList(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadTataPlayCloseBaseList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...params,
					...getLocalAuthDetails(),
				}
				let res = await fetchTataPlayCloseBaseList(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadSipPayoutManagerPredictionDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					segment,
					year,
					flag,
					sub_Flag,
					creator,
					period,
					vertical_tag_id,
					cluster_id,
				} = params
				let payload = {
					...getLocalAuthDetails(),
					flag: flag,
					sub_flag: sub_Flag,
					asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					reportees_asset_id_list: [],
					workforce_tag_id: segment,
					period_type_id: period,
					financial_year: year,
					vertical_tag_id: 0,
					cluster_id: 0,
				}
				let res = await fetchSipPayoutManagerPredictionDetails(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

// export const useLoadSipPayoutReporteesPredictionDetails = () => {
//   return useMutation(async (params = {}) => {
//     try {
//       if (!!params) {
//         const {segment, year, flag, creator,period, timeline_id,sub_Flag} = params
//         let payload = {
//           ...getLocalAuthDetails(),
//           flag: flag,
//           sub_flag: sub_Flag,
//           reportees_asset_id_list: creator,
//           workforce_tag_id: segment,
//           timeline_id: timeline_id,
//           start_from: 0,
//           limit_value: 50,
//           period_type_id: period,
//           financial_year: year
//         }
//         let res = await fetchSipPayoutReporteesPredictionDetails(payload)
//         return res.data.response
//       }
//     } catch (error) {
//       return error
//     }
//   })
// }

export const useLoadSipDynamicManagerPredictionDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { segment, year, flag, creator, period } = params
				let payload = {
					...getLocalAuthDetails(),
					flag: flag,
					asset_id: !!creator
						? creator.asset_id
						: getLocalAuthDetails().asset_id,
					workforce_tag_id: segment,
					period_type_id: period,
					financial_year: year,
					vertical_tag_id: 0,
					cluster_id: 0,
				}
				let res = await fetchSipDynamicManagerPredictionDetails(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadSipDynamicReporteesPredictionDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { segment, year, flag, creator, period, timeline_id } = params
				let payload = {
					...getLocalAuthDetails(),
					flag: flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					start_from: 0,
					limit_value: 50,
					period_type_id: period,
					financial_year: year,
				}
				let res = await fetchSipDynamicReporteesPredictionDetails(payload)
				return res.data.response
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadPenUtilReport = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { segment, year, periodType, reportee } = params
				const requestData1 = {
					...getLocalAuthDetails(),
					asset_id: reportee?.asset_id,
					flag: 0,
					sub_flag: 1,
					workforce_tag_id: segment,
					financial_year: year.replaceAll(' ', ''),
					period_type_id: periodType,
				}
				const requestData2 = {
					...getLocalAuthDetails(),
					...requestData1,
					asset_id: reportee?.asset_id,
					sub_flag: 2,
				}
				const requestData3 = {
					...getLocalAuthDetails(),
					...requestData1,
					asset_id: reportee?.asset_id,
					sub_flag: 3,
				}
				let result
				if (segment === 0) {
					let res_subFlag1 = await fetchSIPPenUtil(requestData1)
					let res_subFlag2 = await fetchSIPPenUtil(requestData2)
					let res_subFlag3 = await fetchSIPPenUtil(requestData3)
					result = [
						...res_subFlag1.data.response,
						...res_subFlag2.data.response,
						...res_subFlag3.data.response,
					]
				} else if (segment === 358) {
					let res_subFlag1 = await fetchSIPPenUtil(requestData1)
					result = res_subFlag1.data.response
				} else if (segment === 184) {
					let res_subFlag3 = await fetchSIPPenUtil(requestData3)
					result = res_subFlag3.data.response
				} else {
					let res_subFlag2 = await fetchSIPPenUtil(requestData2)
					result = res_subFlag2.data.response
				}
				return result
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

//applicable for only Periodic Summary
export const useRolesBasedOnUser = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				let payload = {
					...getLocalAuthDetails(),
					...params,
				}
				let res = await fetchFilterOptionsforPeriodicSummary(payload)

				let data = []
				if (params.flag === 1)
					data = processFilterOptions(res, FILTER_FLAG.CLASSIFY_ROLES, true)
				else if (params.flag === 2)
					data = processFilterOptions(res, FILTER_FLAG.VERTICAL, true)
				else if (params.flag === 3)
					data = processFilterOptions(res, FILTER_FLAG.CLUSTER, true)
				else if (params.flag === 4)
					data = processFilterOptions(res, FILTER_FLAG.CLASSIFY_ROLES, true)
				return data
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadDynamicPayoutAssetManager = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					manager_id,
					timeline_id,
					year,
					period,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag: 1,
					manager_asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					limit_value: 100,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					period_type_id: period,
					financial_year: year,
				}
				let res = await fetchSipDynamicWidgetManagerDetails(requestData)
				let manager = res.data.response
				manager = manager
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				//get emp/qualified sip details with reportees assetId
				let payload = {
					...getLocalAuthDetails(),
					flag: 0,
					sub_flag:
						segment === 358 ? 1 : segment === 185 ? 2 : segment === 184 ? 3 : 0,
					asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					reportees_asset_id_list: [],
					workforce_tag_id: segment,
					period_type_id: period,
					financial_year: year,
					vertical_tag_id: 0,
					cluster_id: 0,
				}
				let result = await fetchSipPayoutReporteesPredictionDetails(payload)
				let managerSipDetails = result.data.response
				managerSipDetails = managerSipDetails
					.filter(
						x =>
							!!x.asset_id &&
							manager.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				// get individual payout details
				const requestBody = {
					...getLocalAuthDetails(),
					account_id: 0,
					asset_type_id: 0,
					manager_asset_id: manager_id,
					asset_id: manager_id || 0, //getLocalAuthDetails.asset_id,
					workforce_tag_id: segment,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: 1,
					flag_type: 0,
					timeline_id: timeline_id,
					period_type_id: period,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year,
					start_from: 0,
					limit_value: 100,
				}
				let details = await fetchSIPPayoutTargets(requestBody)
				let managerIndividualDetails = details.data.response
				managerIndividualDetails = managerIndividualDetails
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				let mergeResponses = _(manager)
					.concat(managerSipDetails, managerIndividualDetails)
					.filter((item, i, arr) => item && arr.indexOf(item) === i)
					.groupBy('asset_workforce_tag_id')
					.map(_.spread(_.merge))
					.value()
				return mergeResponses
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicPayoutAssetReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					flag,
					timeline_id,
					year,
					periodTypeId,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					timeline_id: timeline_id,
					financial_year: year,
					period_type_id: periodTypeId,
					workforce_tag_id: segment,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
				}
				let res = await fetchAssetReportees(requestData)
				let reportees = res.data.response
				reportees = reportees
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				//get emp/qualified sip details with reportees assetId
				let payload = {
					...getLocalAuthDetails(),
					flag: 0,
					sub_flag:
						segment === 358 ? 1 : segment === 185 ? 2 : segment === 184 ? 3 : 0,
					reportees_asset_id_list: JSON.stringify(
						!!reportees && reportees.map(el => el.asset_id)
					),
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					start_from: 0,
					limit_value: 50,
					period_type_id: periodTypeId,
					financial_year: year,
				}
				let result = await fetchSipPayoutReporteesPredictionDetails(payload)
				let reporteeSipDetails = result.data.response
				reporteeSipDetails = reporteeSipDetails
					.filter(
						x =>
							!!x.asset_id &&
							reportees.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				// get individual payout details
				const requestBody = {
					...getLocalAuthDetails(),
					account_id: 0,
					asset_type_id: 0,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: 0, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: 0,
					flag_type: 0,
					timeline_id: timeline_id,
					period_type_id: periodTypeId,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year,
					start_from: 0,
					limit_value: 100,
				}
				let details = await fetchSIPPayoutTargets(requestBody)
				let reporteeIndividualDetails = details.data.response
				reporteeIndividualDetails = reporteeIndividualDetails
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				let mergeResponses = _(reportees)
					.concat(reporteeSipDetails, reporteeIndividualDetails)
					.groupBy('asset_workforce_tag_id')
					.map(_.spread(_.merge))
					.value()
				return mergeResponses
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPWidgetsAccountDetails = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timeline,
					period,
					page,
					endDate,
					year,
					flag,
					is_sip_dynamic_payout,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: 0,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: 0,
					asset_id: creator, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: '', //endDate,
					datetime_start: '', //startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: !!timeline ? timeline.value : 0,
					period_type_id: !!period ? period.value : 1,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: page,
					limit_value: 200,
					is_sip_dynamic_payout: is_sip_dynamic_payout,
				}
				let res = await fetchSipWidgetAccountDetails(requestData)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicPayoutAssetManagerSA = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					manager_id,
					timeline_id,
					year,
					period,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag: 0,
					manager_asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
					workforce_tag_id: 358,
					timeline_id: timeline_id,
					period_type_id: period,
					financial_year: year,
				}
				let res = await fetchSipPayoutManagerDetails(requestData)
				let manager = res.data.response
				manager = manager
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				//get emp/qualified sip details with reportees assetId
				let payload = {
					...getLocalAuthDetails(),
					flag: 0,
					sub_flag: 1,
					asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					reportees_asset_id_list: [],
					workforce_tag_id: 358,
					period_type_id: period,
					financial_year: year,
					vertical_tag_id: 0,
					cluster_id: 0,
				}
				let result = await fetchSipPayoutReporteesPredictionDetails(payload)
				let managerSipDetails = result.data.response
				managerSipDetails = managerSipDetails
					.filter(
						x =>
							!!x.asset_id &&
							manager.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				// get individual payout details
				const requestBody = {
					...getLocalAuthDetails(),
					account_id: 0,
					asset_type_id: 0,
					manager_asset_id: manager_id,
					asset_id: manager_id || 0, //getLocalAuthDetails.asset_id,
					workforce_tag_id: 358,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: 1,
					flag_type: 0,
					timeline_id: timeline_id,
					period_type_id: period,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year,
					start_from: 0,
					limit_value: 100,
					reportees_asset_id_list: JSON.stringify([manager_id]),
				}
				let details = await fetchSIPPayoutTargets(requestBody)
				let managerIndividualDetails = details.data.response
				managerIndividualDetails = managerIndividualDetails.map(v => {
					return !!v && !!v.asset_id
						? {
								...v,
								asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						  }
						: {}
				})
				let mergeResponses = _(manager)
					.concat(managerSipDetails, managerIndividualDetails)
					.filter((item, i, arr) => item && arr.indexOf(item) === i)
					.groupBy('asset_workforce_tag_id')
					.map(_.spread(_.merge))
					.value()
				return mergeResponses
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicPayoutAssetReporteesSA = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					flag,
					timeline_id,
					year,
					periodTypeId,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					asset_type_id:
						creator.asset_type_id !== 0
							? creator.asset_type_id
							: getLocalAuthDetails().asset_type_id,
					flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					timeline_id: timeline_id,
					financial_year: year,
					period_type_id: periodTypeId,
					workforce_tag_id: segment,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
				}
				let res = await fetchAssetReportees(requestData)
				let reportees = res.data.response

				reportees = reportees
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				//get emp/qualified sip details with reportees assetId
				let payload = {
					...getLocalAuthDetails(),
					asset_type_id:
						creator.asset_type_id !== 0
							? creator.asset_type_id
							: getLocalAuthDetails().asset_type_id,
					flag: 0,
					sub_flag:
						segment === 358 ? 1 : segment === 185 ? 2 : segment === 184 ? 3 : 0,
					reportees_asset_id_list: JSON.stringify(
						!!reportees && reportees.map(el => el.asset_id)
					),
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					start_from: 0,
					limit_value: 50,
					period_type_id: periodTypeId,
					financial_year: year,
				}
				let result = await fetchSipPayoutReporteesPredictionDetails(payload)
				let reporteeSipDetails = result.data.response
				reporteeSipDetails = reporteeSipDetails
					.filter(
						x =>
							!!x.asset_id &&
							reportees.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				// get individual payout details
				const requestBody = {
					...getLocalAuthDetails(),
					account_id: 0,
					asset_type_id:
						creator.asset_type_id !== 0
							? creator.asset_type_id
							: getLocalAuthDetails().asset_type_id,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: 0, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: 0,
					flag_type: 0,
					timeline_id: timeline_id,
					period_type_id: periodTypeId,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year,
					start_from: 0,
					limit_value: 100,
					reportees_asset_id_list: JSON.stringify(
						!!reportees &&
							reportees.filter(x => !!x.asset_id).map(v => v.asset_id)
					),
				}
				let details = await fetchSIPPayoutTargets(requestBody)
				let reporteeIndividualDetails = details.data.response
				reporteeIndividualDetails = reporteeIndividualDetails
					.filter(
						x =>
							!!x.asset_id &&
							reportees.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				let mergeResponses = _(reportees)
					.concat(reporteeSipDetails, reporteeIndividualDetails)
					.groupBy('asset_workforce_tag_id')
					.map(_.spread(_.merge))
					.value()
				return mergeResponses
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicPayoutAssetManagerSME = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					manager_id,
					timeline_id,
					year,
					period,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag: 0,
					manager_asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					period_type_id: period,
					financial_year: year,
				}
				let res = await fetchSipPayoutManagerDetails(requestData)
				let manager = res.data.response
				manager = manager
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				//get emp/qualified sip details with reportees assetId
				let payload = {
					...getLocalAuthDetails(),
					flag: 0,
					sub_flag:
						segment === 358 ? 1 : segment === 185 ? 2 : segment === 184 ? 3 : 0,
					asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					reportees_asset_id_list: [],
					workforce_tag_id: segment,
					period_type_id: period,
					financial_year: year,
					vertical_tag_id: 0,
					cluster_id: 0,
				}
				let result = await fetchSipPayoutReporteesPredictionDetails(payload)
				let managerSipDetails = result.data.response
				managerSipDetails = managerSipDetails
					.filter(
						x =>
							!!x.asset_id &&
							manager.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				// get individual payout details
				const requestBody = {
					...getLocalAuthDetails(),
					account_id: 0,
					asset_type_id: 0,
					manager_asset_id: manager_id,
					asset_id: manager_id || 0, //getLocalAuthDetails.asset_id,
					workforce_tag_id: segment,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: 1,
					flag_type: 0,
					timeline_id: timeline_id,
					period_type_id: period,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year,
					start_from: 0,
					limit_value: 100,
				}
				let details = await fetchSIPPayoutTargets(requestBody)
				let managerIndividualDetails = details.data.response
				managerIndividualDetails = managerIndividualDetails.map(v => {
					return !!v && !!v.asset_id
						? {
								...v,
								asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						  }
						: {}
				})
				let mergeResponses = _(manager)
					.concat(managerSipDetails, managerIndividualDetails)
					.filter((item, i, arr) => item && arr.indexOf(item) === i)
					.groupBy('asset_workforce_tag_id')
					.map(_.spread(_.merge))
					.value()
				return mergeResponses
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicPayoutAssetReporteesSME = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					flag,
					timeline_id,
					year,
					periodTypeId,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					asset_type_id:
						creator.asset_type_id !== 0
							? creator.asset_type_id
							: getLocalAuthDetails().asset_type_id,
					flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					timeline_id: timeline_id,
					financial_year: year,
					period_type_id: periodTypeId,
					workforce_tag_id: segment,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
				}
				let res = await fetchAssetReportees(requestData)
				let reportees = res.data.response

				reportees = reportees
					.filter(x => !!x.asset_id)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				//get emp/qualified sip details with reportees assetId
				let payload = {
					...getLocalAuthDetails(),
					asset_type_id:
						creator.asset_type_id !== 0
							? creator.asset_type_id
							: getLocalAuthDetails().asset_type_id,
					flag: 0,
					sub_flag:
						segment === 358 ? 1 : segment === 185 ? 2 : segment === 184 ? 3 : 0,
					reportees_asset_id_list: JSON.stringify(
						!!reportees && reportees.map(el => el.asset_id)
					),
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					start_from: 0,
					limit_value: 50,
					period_type_id: periodTypeId,
					financial_year: year,
				}
				let result = await fetchSipPayoutReporteesPredictionDetails(payload)
				let reporteeSipDetails = result.data.response
				reporteeSipDetails = reporteeSipDetails
					.filter(
						x =>
							!!x.asset_id &&
							reportees.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				// get individual payout details
				const requestBody = {
					...getLocalAuthDetails(),
					account_id: 0,
					asset_type_id:
						creator.asset_type_id !== 0
							? creator.asset_type_id
							: getLocalAuthDetails().asset_type_id,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: 0, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: endDate,
					datetime_start: startDate,
					flag: 0,
					flag_type: 0,
					timeline_id: timeline_id,
					period_type_id: periodTypeId,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year,
					start_from: 0,
					limit_value: 100,
					reportees_asset_id_list: JSON.stringify(
						!!reportees &&
							reportees.filter(x => !!x.asset_id).map(v => v.asset_id)
					),
				}
				let details = await fetchSIPPayoutTargets(requestBody)
				let reporteeIndividualDetails = details.data.response
				reporteeIndividualDetails = reporteeIndividualDetails
					.filter(
						x =>
							!!x.asset_id &&
							reportees.filter(e => e.asset_id === x.asset_id).length
					)
					.map(v => {
						return {
							...v,
							asset_workforce_tag_id: v.asset_id + '::' + v.workforce_tag_id,
						}
					})
				let mergeResponses = _(reportees)
					.concat(reporteeSipDetails, reporteeIndividualDetails)
					.groupBy('asset_workforce_tag_id')
					.map(_.spread(_.merge))
					.value()
				return mergeResponses
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPWidgetsSARoleList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const { segment } = params
				let payload = {
					...getLocalAuthDetails(),
					workforce_tag_id: segment,
				}
				let res = await fetchSipWidgetSARoleList(payload)
				return Object.values(res.data.response)
			}
		} catch (error) {
			return error
		}
	})
}

export const useLoadSIPPayoutWidgetsMonthWise = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timeline,
					period,
					startDate,
					endDate,
					year,
					flag,
					reportees_asset_id_list,
					is_sip_dynamic_payout,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: 0,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: flag === 0 ? 0 : !!creator && creator.asset_id, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: '', //endDate,
					datetime_start: '', //startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: !!timeline ? timeline.value : 0,
					period_type_id: !!period ? period.value : 1,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: 0,
					limit_value: 100,
					is_sip_dynamic_payout: is_sip_dynamic_payout,
					reportees_asset_id_list: reportees_asset_id_list,
				}
				let res = await fetchSipWidgetDynamicMonthWiseData(requestData)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPWidgetsAccountcodesList = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timeline,
					period,
					page,
					endDate,
					year,
					flag,
					is_sip_dynamic_payout,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: 0,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: 0,
					asset_id: creator, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: '', //endDate,
					datetime_start: '', //startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: !!timeline ? timeline.value : 0,
					period_type_id: !!period ? period.value : 1,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: 0,
					limit_value: 0,
					is_sip_dynamic_payout: is_sip_dynamic_payout,
				}
				let res = await fetchSipWidgetAccountCodeList(requestData)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const LoadSIPWidgetsAccountcodesMonthWiseData = async params => {
	// return useMutation(async (params = {}) => {
	//   try {
	if (!!params) {
		const {
			creator,
			segment,
			timeline,
			period,
			page,
			endDate,
			year,
			flag,
			is_sip_dynamic_payout,
			customer_account_code,
		} = params
		const userData = getLocalAuthDetails()
		const requestData = {
			...userData,
			account_id: 0,
			asset_type_id: 0,
			filter_organization_id: userData.organization_id || -1,
			manager_asset_id: 0,
			asset_id: creator, //userData.asset_id,
			workforce_tag_id: segment,
			datetime_end: '', //endDate,
			datetime_start: '', //startDate,
			flag: flag,
			flag_type: 0,
			timeline_id: !!timeline ? timeline.value : 0,
			period_type_id: !!period ? period.value : 1,
			period_start_datetime: '',
			period_end_datetime: '',
			financial_year: year.value.replaceAll(' ', ''),
			start_from: 0,
			limit_value: 0,
			is_sip_dynamic_payout: is_sip_dynamic_payout,
			customer_account_code: customer_account_code,
		}
		let res = await fetchSipWidgetAccountMonthWiseData(requestData)
		if (res.status === 200) {
			return res.data.response
		} else {
			return []
		}
	}
	// } catch (error) {
	//   console.log('Error', error)
	//   return error
	// }
	// }
	// )
}

export const useLoadSIPDynamicWidgetsManagerDetailsKPI = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timeline,
					period,
					startDate,
					endDate,
					year,
					flag,
					reportees_asset_id_list,
					is_sip_dynamic_payout,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: !!creator && creator.asset_id,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: flag === 0 ? 0 : !!creator && creator.asset_id, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: '', //endDate,
					datetime_start: '', //startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: !!timeline ? timeline.value : 0,
					period_type_id: !!period ? period.value : 1,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: 0,
					limit_value: 100,
					is_sip_dynamic_payout: is_sip_dynamic_payout,
					reportees_asset_id_list: reportees_asset_id_list,
				}
				let res = await fetchSipDynamicWidgetManagerDetailsQuaterly(requestData)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadSIPDynamicWidgetsReporteeDetailsKPI = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					segment,
					timeline,
					period,
					startDate,
					endDate,
					year,
					flag,
					reportees_asset_id_list,
					is_sip_dynamic_payout,
				} = params
				const userData = getLocalAuthDetails()
				const requestData = {
					...userData,
					account_id: 0,
					asset_type_id: 0,
					filter_organization_id: userData.organization_id || -1,
					manager_asset_id: !!creator && creator.asset_id,
					asset_id: flag === 0 ? 0 : !!creator && creator.asset_id, //userData.asset_id,
					workforce_tag_id: segment,
					datetime_end: '', //endDate,
					datetime_start: '', //startDate,
					flag: flag,
					flag_type: 0,
					timeline_id: !!timeline ? timeline.value : 0,
					period_type_id: !!period ? period.value : 1,
					period_start_datetime: '',
					period_end_datetime: '',
					financial_year: year.value.replaceAll(' ', ''),
					start_from: 0,
					limit_value: 120,
					is_sip_dynamic_payout: is_sip_dynamic_payout,
					reportees_asset_id_list: reportees_asset_id_list,
				}
				let res = await fetchSipDynamicWidgetReporteeDetailsQuaterly(
					requestData
				)
				if (res.status === 200) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicWidgetManager = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					manager_id,
					timeline_id,
					year,
					period,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag: 1,
					manager_asset_id:
						manager_id !== 0 ? manager_id : getLocalAuthDetails().asset_id,
					limit_value: 100,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
					workforce_tag_id: segment,
					timeline_id: timeline_id,
					period_type_id: period,
					financial_year: year,
				}
				let res = await fetchSipDynamicWidgetManagerDetails(requestData)

				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}

export const useLoadDynamicWidgetReportees = () => {
	return useMutation(async (params = {}) => {
		try {
			if (!!params) {
				const {
					creator,
					flag,
					timeline_id,
					year,
					periodTypeId,
					segment,
					startDate,
					endDate,
				} = params
				const requestData = {
					...getLocalAuthDetails(),
					log_asset_id: getLocalAuthDetails().asset_id,
					flag,
					manager_asset_id:
						creator.asset_id !== 0
							? creator.asset_id
							: getLocalAuthDetails().asset_id,
					timeline_id: timeline_id,
					financial_year: year,
					period_type_id: periodTypeId,
					workforce_tag_id: segment,
					limit_value: 50,
					page_limit: 50,
					page_start: 0,
					start_from: 0,
				}
				let res = await fetchAssetReportees(requestData)
				return res.data.response
			}
		} catch (error) {
			console.log('Error', error)
			return error
		}
	})
}
